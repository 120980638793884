import actions from './actions';
import gettersFile from './getters';
import mutationsFile from './mutations';
import {
	make
} from 'vuex-pathify';

export const initialState = () => ({
	reviews: {
		data: [],
		start: 0,
		limit: 50,
		total: 0
	}
});


const mutations = {
	...make.mutations(initialState()),
	...mutationsFile
};

const getters = {
	...make.getters(initialState()),
	...gettersFile
};

export default function createProductsStore() {
	return {
		namespaced: true,
		state: initialState,
		actions,
		getters,
		mutations,
	};
}