import {
	getReviews,
	respondReview,
	deleteReview,
	approveReview
} from '@/api/comunicare';
import store from '../../index';

export default {
	GetProductsReviews: ({
		state
	}, more) => {
		if (more) store.set('products/reviews@start', +state.reviews.start + +state.reviews.limit);
		return getReviews({
			start: state.reviews.start
		}).then((res) => store.set('products/reviews', res.message));
	},

	SubmitResponse: ({
		state
	}, {
		response,
		reviewId
	}) => respondReview({
		response,
		reviewId,
		name: store.get('user/info@name')
	}).then((res) => store.set('products/reviews', res.message)),

	DeleteReview: ({
		state
	}, {
		reviewId
	}) => deleteReview({
		reviewId
	}).then((res) => store.set('products/reviews', res.message)),
	ApproveReview: ({}, {
		reviewId
	}) => approveReview({
		reviewId
	}).then((res) => store.set('products/reviews', res.message))
};