<template>
	<div class="app-container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.reviews.page-title')}}</span>
			</div>
			<div class="container">
				<template v-for="(review, i) in reviews">
					<Item :item="review" :key="i" class="item"></Item>
				</template>
				<div class="load-more-container" v-if="total >= (limit + start)">
					<el-button type="primary" class="load-more" icon="el-icon-more-outline" plain @click="loadMore">
						{{$t('general.loadMore')}}</el-button>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import createProductsStore from '@/store/modules/products';
	import {
		get,
		call,
		sync
	} from 'vuex-pathify';
	const Item = () => import('./item');
	export default {
		components: {
			Item
		},
		data: () => ({

		}),
		methods: {
			getProductsReviews: call('products/GetProductsReviews'),
			async loadMore() {
				try {
					await this.getProductsReviews(true);
				} catch (err) {
					this.$reportToSentry(err, {
						extra: {
							fn: 'loadMoreReviews'
						}
					});
				}
			}
		},
		computed: {
			...sync('products/reviews@', {
				total: 'total',
				reviews: 'data',
				limit: 'limit',
				start: 'start'
			}),
		},
		async mounted() {
			try {
				await this.getProductsReviews();
			} catch (err) {
				this.$reportToSentry(err, {
					extra: {
						fn: 'getProductsReviews'
					}
				});
			}
		},
		beforeCreate() {
			this.$store.registerModule('products', createProductsStore());
		},
		beforeDestroy() {
			this.$store.unregisterModule('products');
		}
	};
</script>

<style scoped>
	.item:not(:last-child) {
		margin-bottom: 10px;
	}

	.load-more-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
</style>